/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-restricted-syntax */

import * as React from 'react';
import { useEffect, useState, FC } from 'react';
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
//@ts-ignore
import { BallTriangle } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import { LocalizedPageProps } from '../utils/types';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import { SectionSsu } from '../components/partials/ssu';
import { SectionNoSsu } from '../components/partials/ssu/alternate';

const IndexPage: FC<LocalizedPageProps> = ({ pageContext }) => {
  let IString: string;
  let IBoolean: boolean;
  const { t } = useTranslation('ssu');
  const { lang, alternateUrls } = pageContext;

  const [code, setCode] = useState<typeof IString>('DE');
  const [timezone, setTimezone] = useState<typeof IString>('Europe/Berlin');
  const [loading, setLoading] = useState<typeof IBoolean>(true);
  const [blocked, setBlocked] = useState<typeof IBoolean>(false);

  const callGeoplugin = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        'https://api.ipgeolocation.io/ipgeo?apiKey=9b51f3e5aa9f4c02a6ae2d6746e5eede'
      );
      const body = await response.json();
      setCode(body.country_code2);
      checkRegionBlock(body.country_code2);
      setTimezone(body.time_zone.name);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const checkRegionBlock = (code) => {
    for (const country of bannedCountryList) {
      if (code === country) {
        setBlocked(true);
        break;
      }
    }
  };

  useEffect(() => {
    callGeoplugin();
  }, []);

  const bannedCountryList = [
    'GN',
    'LT',
    'SO',
    'CI',
    'ZM',
    'EG',
    'MA',
    'KE',
    'NG',
    'GH',
    'BG',
    'CN',
    'IN',
    'BY',
    'UA',
    'RU',
    'RO',
    'PL',
  ];

  if (loading)
    return (
      <div className="loader-wrapper">
        <BallTriangle color="#00BFFF" height={100} width={300} />
      </div>
    );
  let canonical = null;
  if (pageContext.key === 'ssu_with_video') {
    canonical = 'https://shore.com/de/kostenlos-testen';
  }
  return (
    <Layout
      pageContext={pageContext}
      secondNav
      navClasses="transparent"
      mainClasses="  "
    >
      <SEO
        lang={lang}
        title={t('meta.title')}
        description={t('meta.description')}
        alternateUrls={alternateUrls}
        canonical={canonical}
      />
      {!blocked ? (
        <SectionSsu
          translation={t}
          code={code}
          timezone={timezone}
          formType={pageContext.key}
        />
      ) : (
        <SectionNoSsu blocked translation={t} />
      )}
    </Layout>
  );
};

export default IndexPage;
